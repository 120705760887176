import { createContext } from 'react';

const PoolContext = createContext(
    {
        status: {}
    });

export {
    PoolContext
};

