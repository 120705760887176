// import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers'
import React, {useState, useEffect, useContext} from 'react'
import { Row, Col, Card, CardTitle, Alert, CardText, Input, Button, Badge, InputGroup, InputGroupAddon, InputGroupText, Progress} from 'reactstrap'
import { useStakingPoolContract, useHegicContract, useNewStakingPoolContract, useZHegicContract } from '../../contracts/useContract';
import { formatBN, truncateEtherValue, Asset } from '../../utils'
import { WalletContext } from '../../context/Wallet'

function MigrateTabZLot() {
    const wallet = useContext(WalletContext);
    const {account, library, chainId } = wallet.context;

    const zHEGIC = useZHegicContract();
    const stakingPool = useStakingPoolContract();
    const newStakingPool = useNewStakingPoolContract();

    const [statusMsg, setStatusMsg] = useState();
    const [totalBalance, setTotalBalance] = useState(ethers.BigNumber.from('0'));
    const [lockedBalance, setLockedBalance] = useState(ethers.BigNumber.from('0'));
    const [allowanceIsZero, setAllowanceIsZero] = useState(true);

    const [totalNumberOfStakingLots, setTotalNumberOfStakingLots] = useState(0);
    const [numberOfStakingLotsETH, setNumberOfStakingLotsETH] = useState(0);
    const [numberOfStakingLotsWBTC, setNumberOfStakingLotsWBTC] = useState(0);
    
    const [amountToDeposit, setAmountToDeposit] = useState(ethers.BigNumber.from('0'));
    const [depositButtonEnabled, setDepositButtonEnabled] = useState(false);

    useEffect(() => {
        let status = true;
        status = status && (amountToDeposit > 0 || amountToDeposit == '');
        status = status && !allowanceIsZero;  
        status = status && wallet.balances.zHEGICBalance.value != 0
   
        if(amountToDeposit != '')  
            status = status && ethers.utils.parseEther(amountToDeposit).lte(wallet.balances.HEGICBalance.value);
        setDepositButtonEnabled(status);
    }, [wallet.balances.zHEGICBalance, allowanceIsZero, amountToDeposit])

    useEffect(() => {
        setAllowanceIsZero(wallet.allowances.zHEGICAllowance.value.isZero());
    }, [wallet.allowances]);

    useEffect(() => {
        if(!!account && !!library){
            setAmountToDeposit('');

            stakingPool.totalBalance().then(
                (balance) => setTotalBalance(balance)
            );
            stakingPool.lockedBalance().then(
                (balance) => setLockedBalance(balance)
            );
            stakingPool.totalNumberOfStakingLots().then(
                (n) => setTotalNumberOfStakingLots(n.toNumber())
            );
            stakingPool.numberOfStakingLots(Asset.ETH).then(
                (n) => setNumberOfStakingLotsETH(n.toNumber())
            );
            stakingPool.numberOfStakingLots(Asset.WBTC).then(
                (n) => setNumberOfStakingLotsWBTC(n.toNumber())
            );
        }
     }, [wallet.balances]);

    const allow = async () => {
        const amountToAllow = ethers.BigNumber.from("8880000000000000000000000000000");
        const txRequest = await zHEGIC.approve(newStakingPool.address, amountToAllow);
        
        await waitAndUpdate(txRequest);
    }

    const waitAndUpdate = async (txRequest) => {
        setStatusMsg( (<a style={{color:'#19274d'}} target='_blank' href={`https://etherscan.io/tx/${txRequest.hash}`}>Pending transaction {txRequest.hash}</a> ) );
        await txRequest.wait();
        setStatusMsg("");
    }

    const depositMax = () => {
        setAmountToDeposit(ethers.utils.formatEther(wallet.balances.HEGICBalance.value));
    }

    const migrateHegic = async () => {
        let amount;
        if(amountToDeposit == '') 
            amount = wallet.balances.HEGICBalance.value
        else 
            amount = ethers.utils.parseEther(amountToDeposit); 
        const zPoolAddress = "0x6eC088B454d2dB7a2d8879A25d9ce015039E30FB"
        const txRequest = await newStakingPool.migrateFromOldStakingPool(zPoolAddress);
        setAmountToDeposit(0);
        await waitAndUpdate(txRequest);
    }

    const StatusMsg = () => {
        return (
            <>
            { statusMsg ? (
                <Alert color="primary" style={{marginTop:'10px'}}>
                    {statusMsg}
                </Alert> 
            ) : null }
            </>
        );
    }

    return (
        <Row>
        <Col sm="12">
            <Card body>
                <CardTitle><h3>Migrate ZLot</h3></CardTitle>
                <CardText>
                    <Badge color='secondary'>{totalNumberOfStakingLots} Staking Lots Purchased</Badge> <Badge color='primary'>{numberOfStakingLotsETH} ETH Lots</Badge> <Badge color='warning'>{numberOfStakingLotsWBTC} BTC Lots</Badge> <Badge color='secondary'>{truncateEtherValue(formatBN(totalBalance), 3)} HEGIC in pool</Badge>
                    <Alert style={{textAlign:'center', margin:'5pt', padding:'5pt', fontSize:'10pt'}} color="warning">
                            HegicStaking has been updated to V8888. You should move your funds to the <a href="https://www.hegicstaking.co" style={{'color':'#121c36'}}>new version</a>.
                    </Alert>
                    
                    Hegic Staking has been migrated to v8888. You can migrate your funds and enjoy a 20% discounted fee<br />
                    &nbsp;&nbsp;1. Approve the new Hegic Staking Pool to manage your sHEGIC<br />
                    &nbsp;&nbsp;2. Migrate funds and enjoy your low fees. <br />
                    &nbsp;&nbsp;3. Go back to high APYs (currently +45%) and enjoy whale profits<br />
               </CardText>
               <Badge color={wallet.balances.zHEGICBalance.value != 0 ? "primary" : "warning"} style={{fontSize:'9pt'}}>You have {truncateEtherValue(formatBN(wallet.balances.zHEGICBalance.value),2)} zHEGIC</Badge>
                { allowanceIsZero && wallet.balances.zHEGICBalance.value != 0 ?
                    (<Button className="main-button" onClick={allow}>APPROVE zHEGIC</Button>)
                    :
                    ( null ) 
                }
                <Button className="main-button" disabled={ !depositButtonEnabled ? true : false } onClick={migrateHegic}><b>MIGRATE</b></Button>
                <StatusMsg />
            </Card>
        </Col>
      </Row>
    )
}

export default MigrateTabZLot;